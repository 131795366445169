import { render, staticRenderFns } from "./MedicalTrendReview.vue?vue&type=template&id=0860bd49&scoped=true&"
import script from "./MedicalTrendReview.vue?vue&type=script&lang=js&"
export * from "./MedicalTrendReview.vue?vue&type=script&lang=js&"
import style0 from "./MedicalTrendReview.vue?vue&type=style&index=0&id=0860bd49&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0860bd49",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("d:\\workspace\\liveinfo3-front-user-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0860bd49')) {
      api.createRecord('0860bd49', component.options)
    } else {
      api.reload('0860bd49', component.options)
    }
    module.hot.accept("./MedicalTrendReview.vue?vue&type=template&id=0860bd49&scoped=true&", function () {
      api.rerender('0860bd49', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/event/MedicalTrendReview.vue"
export default component.exports